import {
  Anchor,
  Container,
  Flex,
  List,
  Space,
  Stack,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";

import { ReactComponent as LogoSvg } from "@/assets/logo.svg";
import { useStyles } from "./styles";

const { VITE_STAGE_DOMAIN } = import.meta.env;

type LinkItem = {
  label: string;
  href: string;
};

const docsLink = `https://docs.${VITE_STAGE_DOMAIN}`;
const blogLink = `https://blog.${VITE_STAGE_DOMAIN}`;

const PRODUCT_LINKS: LinkItem[] = [
  {
    label: "Affiliate Program (earn 40$ per sale)",
    href: "https://scaletozeroaws.lemonsqueezy.com/affiliates",
  },
  {
    label: "Request a feature",
    href: "https://scaletozeroaws.featurebase.app/",
  },
  { label: "Docs", href: docsLink },
  { label: "Blog", href: blogLink },
  // { label: "Roadmap", href: "roadmap" },
];
const LEGAL_LINKS: LinkItem[] = [
  { label: "Terms of Service", href: "terms-of-service" },
  { label: "Privacy Policy", href: "privacy-policy" },
  { label: "License", href: "license" },
];

const SERVICES_BUILT = [
  {
    label: "Form Slides (unlimited forms and responses)",
    href: "https://formslides.com",
  },
  {
    label: "File ChatAI (acquired)",
    href: "https://filechatai.com",
  },
];

const Footer = () => {
  const { classes } = useStyles();
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <footer className={classes.footer}>
      {isMobile ? (
        <Container>
          {/* Mobile View */}
          <Stack spacing={8} className={classes.footer_section}>
            <Title order={5}>Product</Title>
            <List listStyleType="none">
              {PRODUCT_LINKS.map(({ label, href }) => (
                <List.Item key={label}>
                  <Anchor
                    component={Link}
                    to={href}
                    underline={false}
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {label}
                  </Anchor>
                </List.Item>
              ))}
            </List>
          </Stack>
          <Space h="xl" />
          <Stack spacing={8} className={classes.footer_section}>
            <Title order={5}>Legal</Title>
            <List listStyleType="none">
              {LEGAL_LINKS.map(({ label, href }) => (
                <List.Item key={label}>
                  <Anchor
                    component={Link}
                    to={href}
                    underline={false}
                    className={classes.link}
                  >
                    {label}
                  </Anchor>
                </List.Item>
              ))}
            </List>
          </Stack>
          <Space h="xl" />

          <Stack spacing={8} className={classes.footer_section}>
            <Title order={5}>Built with this kit</Title>
            <List listStyleType="none">
              {SERVICES_BUILT.map(({ label, href }) => (
                <List.Item key={label}>
                  <Anchor
                    component={Link}
                    to={href}
                    underline={false}
                    className={classes.link}
                  >
                    {label}
                  </Anchor>
                </List.Item>
              ))}
            </List>
          </Stack>
          <Space h="xl" />

          <Stack spacing={8} className={classes.footer_section}>
            <div className={classes.copyright_mobile}>
              <div className={classes.logo_mobile}>
                <LogoSvg style={{ width: "15%" }} />
                <div className={classes.logo_text}>Scale to Zero AWS</div>
              </div>
              <div className={classes.all_rights_reserved_text}>
                &copy; {new Date().getFullYear()} All rights reserved.
              </div>
            </div>
          </Stack>
        </Container>
      ) : (
        <Container>
          {/* Desktop View */}
          <Flex justify="space-between" wrap="wrap" gap="xl">
            <Stack spacing={8} className={classes.footer_section}>
              <div className="copyright">
                <div className={classes.logo}>
                  <LogoSvg style={{ width: "15%" }} />
                  <div className={classes.logo_text}>Scale to Zero AWS</div>
                </div>

                <div className={classes.all_rights_reserved_text}>
                  &copy; {new Date().getFullYear()} All rights reserved.
                </div>
              </div>
            </Stack>
            <Stack spacing={8} className={classes.footer_section}>
              <Title order={5}>Product</Title>
              <List listStyleType="none">
                {PRODUCT_LINKS.map(({ label, href }) => (
                  <List.Item key={label}>
                    <Anchor
                      component={Link}
                      to={href}
                      underline={false}
                      className={classes.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {label}
                    </Anchor>
                  </List.Item>
                ))}
              </List>
            </Stack>
            <Stack spacing={8} className={classes.footer_section}>
              <Title order={5}>Legal</Title>
              <List listStyleType="none">
                {LEGAL_LINKS.map(({ label, href }) => (
                  <List.Item key={label}>
                    <Anchor
                      component={Link}
                      to={href}
                      underline={false}
                      className={classes.link}
                    >
                      {label}
                    </Anchor>
                  </List.Item>
                ))}
              </List>
            </Stack>

            <Stack spacing={8} className={classes.footer_section}>
              <Title order={5}>Built with this kit</Title>
              <List listStyleType="none">
                {SERVICES_BUILT.map(({ label, href }) => (
                  <List.Item key={label}>
                    <Anchor
                      component={Link}
                      to={href}
                      underline={false}
                      className={classes.link}
                    >
                      {label}
                    </Anchor>
                  </List.Item>
                ))}
              </List>
            </Stack>
          </Flex>
        </Container>
      )}
    </footer>
  );
};

export default Footer;
