import { gtag_report_conversion } from "@/util";
import { Button, Card, Flex, List, Stack, Text } from "@mantine/core";
import { TickSquare } from "iconsax-react";

import { FreeCardProps } from "../../types/pricing";
import { useStyles } from "./styles";

const { VITE_LS_BASIC } = import.meta.env;

const FreeCard = ({ package_data }: FreeCardProps) => {
  const { classes } = useStyles();
  const { price, name, bestFor, features, CTA } = package_data;

  const handleBasicPackageButtonClick = () => {
    const lemonSqueezyBasicCheckoutUrl = `https://scaletozeroaws.lemonsqueezy.com/checkout/buy/${VITE_LS_BASIC}?checkout[discount_code]=G5NJE1MW`;
    gtag_report_conversion(lemonSqueezyBasicCheckoutUrl);
    window.open(lemonSqueezyBasicCheckoutUrl, "_blank");
  };

  return (
    <Card padding="none" className={classes.pricing_card}>
      <Stack pt={56} px={24} pb={32} spacing={48}>
        <Stack>
          <Text size="lg">{name}</Text>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              weight="bold"
              color="white"
              size={"1.5rem"}
              mr="xs"
              style={{ textDecoration: "line-through", fontSize: "15px" }}
            >
              {typeof price === "string" && price}
            </Text>
            <Text weight="bold" color="white" size={"1.5rem"}>
              80$
            </Text>
          </div>
          <Flex justify="center" align="center" gap="sm">
            <Text size="sm">{bestFor ? bestFor : <>&nbsp;</>}</Text>
          </Flex>
        </Stack>
        <div className={classes.divider}></div>
        <List
          withPadding
          size="sm"
          icon={<TickSquare size="16" color="#697689" variant="Bulk" />}
          className={classes.features}
        >
          {features.map((feature) => (
            <List.Item mb="sm" key={feature}>
              {feature}
            </List.Item>
          ))}
        </List>
        <Button
          variant="default"
          my="xl"
          radius="xl"
          fullWidth
          onClick={handleBasicPackageButtonClick}
        >
          {CTA}
        </Button>
      </Stack>
    </Card>
  );
};

export default FreeCard;
