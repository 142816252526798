import React, { useEffect, useRef } from "react";
import {
  Anchor,
  Burger,
  Button,
  Divider,
  Drawer,
  Group,
  Header,
  Menu,
  ScrollArea,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useCookies } from "react-cookie";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { ReactComponent as LogoSvg } from "@/assets/logo.svg";
import "../landing.css";
import { useStyles } from "./styles";

type NavigationItem = {
  label: string;
  href: string;
};

const Navigations: NavigationItem[] = [
  // { label: "Why this kit", href: "why-this-kit" },
  { label: "Features", href: "/#features" },
  { label: "Pricing", href: "/#pricing" },
  { label: "FAQ", href: "/#faq" },
  // external links
  { label: "Docs", href: "https://docs.scaletozeroaws.com" },
  { label: "Blog", href: "https://scaletozeroaws.com/blog" },
];

function Navbar() {
  const [cookies] = useCookies(["isLoggedIn"]);
  const isLoggedIn = cookies.isLoggedIn === "true";

  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const { classes, theme } = useStyles();

  const location = useLocation();

  React.useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const locationPathname = location.pathname;
  const previousPage = useRef(locationPathname);
  useEffect(() => {
    if (locationPathname !== previousPage.current) {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // for older browser
        window.scrollTo(0, 0);
      }
      previousPage.current = locationPathname;
    }
  }, [locationPathname]);

  return (
    <>
      <Header
        height={50}
        mb={10}
        px="md"
        bg="transparent"
        className={classes.header}
      >
        <Group position="apart" sx={{ height: "100%" }}>
          <Group
            sx={{
              height: "100%",
              "& svg": {
                witdh: 35,
                height: 35,
                marginTop: "7px",
              },
            }}
          >
            <Anchor component={Link} to="/" className={classes.logoContainer}>
              <LogoSvg />
              <span className={classes.logoText}>Scale to Zero AWS</span>
            </Anchor>

            <Group
              sx={{ height: "100%" }}
              spacing={0}
              className={classes.hiddenMobile}
            >
              <Menu trigger="hover" openDelay={100} closeDelay={400}>
                <Menu.Target>
                  <Button>Why this kit</Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    component={Link}
                    to="/vs-nextjs-and-vercel"
                    className="why-this-kit-dropdown-item"
                  >
                    vs Next.js boilerplates / Vercel
                  </Menu.Item>

                  <Menu.Item
                    component={Link}
                    to="/vs-sst-dev"
                    className="why-this-kit-dropdown-item"
                  >
                    vs sst.dev
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
              {Navigations.map(({ label, href }) => (
                <Anchor
                  component={HashLink}
                  to={href}
                  underline={false}
                  className={classes.link}
                  key={label}
                >
                  {label}
                </Anchor>
              ))}
            </Group>
          </Group>

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className={classes.hiddenDesktop}
          />
        </Group>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.0"}
          />
          <Menu trigger="click" openDelay={100} closeDelay={400}>
            <Menu.Target>
              <Button>Why this kit</Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                component={Link}
                to="/vs-nextjs-and-vercel"
                className="why-this-kit-dropdown-item"
              >
                vs Next.js boilerplates / Vercel
              </Menu.Item>

              <Menu.Item
                component={Link}
                to="/vs-sst-dev"
                className="why-this-kit-dropdown-item"
              >
                vs sst.dev
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          {Navigations.map(({ label, href }) => (
            <Anchor
              href={href}
              onClick={closeDrawer}
              underline={false}
              className={classes.link}
              key={label}
            >
              {label}
            </Anchor>
          ))}
        </ScrollArea>
      </Drawer>
    </>
  );
}

export default Navbar;
