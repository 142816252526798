import { gtag_report_conversion } from "@/util";
import { Button, Card, Flex, List, Stack, Text } from "@mantine/core";
import { TickSquare } from "iconsax-react";

import { GoldCardProps } from "../../types/pricing";
import { useStyles } from "./styles";

const { VITE_LS_GOLD } = import.meta.env;

const GoldCard = ({ package_data }: GoldCardProps) => {
  const { classes } = useStyles();
  const { name, price, bestFor, features, CTA } = package_data;

  const handleGoldPackageButtonClick = () => {
    const lemonSqueezyGoldCheckoutUrl = `https://scaletozeroaws.lemonsqueezy.com/checkout/buy/${VITE_LS_GOLD}?checkout[discount_code]=G5NJE1MW`;
    gtag_report_conversion(lemonSqueezyGoldCheckoutUrl);
    window.open(lemonSqueezyGoldCheckoutUrl, "_blank");
  };

  return (
    <Card
      padding="none"
      className={`${classes.pricing_card} ${classes.gold_card}`}
    >
      <div className={classes.popular_label}>recommended</div>{" "}
      <Stack pt={56} px={24} pb={32} spacing={48}>
        <Stack>
          <Text size="lg">{name}</Text>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              weight="bold"
              color="white"
              size={"1.5rem"}
              mr="xs"
              style={{ textDecoration: "line-through", fontSize: "15px" }}
            >
              {typeof price === "string" && price}
            </Text>
            <Text weight="bold" color="white" size={"1.5rem"}>
              120$
            </Text>
          </div>

          {/* <Text weight="bold" color="white" size={"1.5rem"}>
            {typeof price === "string" && price}
          </Text> */}
          <Flex justify="center" align="center" gap="sm">
            <Text size="sm">{bestFor ? bestFor : <>&nbsp;</>}</Text>
          </Flex>
        </Stack>
        <div className={classes.divider}></div>
        <List
          withPadding
          size="sm"
          icon={<TickSquare size="16" color="#697689" variant="Bulk" />}
          className={classes.features}
        >
          <Text mb="sm">Everything from Basic, plus</Text>

          {features.map((feature) => (
            <List.Item mb="sm" key={feature}>
              {feature}
            </List.Item>
          ))}
        </List>
        <Button
          variant="default"
          my="xl"
          radius="xl"
          fullWidth
          onClick={handleGoldPackageButtonClick}
        >
          {CTA}
        </Button>
      </Stack>
    </Card>
  );
};

export default GoldCard;
